








import { defineComponent, ref } from '@nuxtjs/composition-api';
import ContentBlocks from '~/components/ContentBlocks.vue';
import HTMLContent from '~/components/HTMLContent.vue';

export default defineComponent({
  name: 'HeaderNavigationSubcategoriesCustom',
  components: {
    ContentBlocks,
    HTMLContent,
  },
  props: {
    category: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const blockContent = ref(props.category?.content);

    // after change from v-if to v-show, there is no need to watch changes in props.category anymore
    // watch(
    //   () => props.category,
    //   async (newVariance) => {
    //     blockContent.value = newVariance?.content;
    //   }
    // );

    return {
      blockContent,
    };
  },
});
